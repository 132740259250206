
/*
* A dropdown module to handle dropdown display and aria-attributes based on defined
* `data-` and `hidden` attributes.
*
* Usage instructions:
*
* 1. Include the module file on your page (preferably in the end)
* 2. Initialize the module with: dropdown.init();
* 3. Add an `id` to each `flix-dropdown__items` element you wish to show/hide
* 4. Connect the target to the dropdown items by passing the dropdown list id to the target
*    `data-dropdown` attribute
* 5. Optionally you can configure if the dropdown is to be shown on click or hover by
*    passing `data-event="click|hover" to the target
*/

/*global define*/
/*eslint no-undef: ["error", { "typeof": true }] */

(function (root, factory) {
  if ( typeof define === 'function' && define.amd ) {
    // AMD. Register as an anonymous module.
    define([], factory(root));
  } else if ( typeof exports === 'object' ) {
    module.exports = factory(root);
  } else {
    // Browser globals (root is window)
    root.dropdown = factory();
  }
})(typeof global !== 'undefined' ? global : this.window || this.global, function () {

  'use strict';

  var dropdown = {};

  // selector for the target elements that we should apply the module handlers
  var TARGET_SELECTOR = 'data-dropdown';
  // dropdown active modifier
  var DROPDOWN_CLASS = 'flix-dropdown';
  var ACTIVE_MODIFIER = 'flix-dropdown--active';
  // allowed events to listen to
  var ALLOWED_EVENTS = ['click', 'hover'];
  var DEFAULT_EVENT = 'click';
  // DELAY (in milliseconds) to trigger toggle when event is hover
  var DELAY = '300';


  /**
   * Returns the dropdown event if it's an allowed event, otherwise
   * returns the default event.
   * @param {Node} target
   * @returns "click" or "hover"
   */
   var getDropdownEvent = function(target) {
    var customEvent = target.getAttribute('data-event');

    if (customEvent && ALLOWED_EVENTS.indexOf(customEvent.toLowerCase()) >= 0) {
      return customEvent;
    }

    return DEFAULT_EVENT;
  };

  /**
   * Toggles dropdown based on the current state of the list's `hidden` attribute
   * @private
   */
  var toggleDropdown = function (target, dropdownElement) {
    if (dropdownElement.hasAttribute('hidden')) {
      showDropdown(target, dropdownElement);
    } else {
      hideDropdown(target, dropdownElement);
    }
  };

  /**
   * Shows dropdown and sets target expanded to true if "click" event
   * @private
   */
  var showDropdown = function (target, dropdownElement) {
    dropdownElement.removeAttribute('hidden');
    target.setAttribute('aria-expanded', 'true');
    if (target.parentElement.classList.contains(DROPDOWN_CLASS)) {
      target.parentElement.classList.add(ACTIVE_MODIFIER);
    }
  };

  /**
   * Hides dropdown and sets target expanded to false if "click" event
   * @private
   */
  var hideDropdown = function (target, dropdownElement) {
    dropdownElement.setAttribute('hidden', '');
    target.setAttribute('aria-expanded', 'false');
    target.parentElement.classList.remove(ACTIVE_MODIFIER);
  };

  /**
   * Event handlers for when data-event is `click`
   * Default behaviour
   * @private
   */
  var handleClick = function (target, dropdownElement) {
    target.addEventListener('click', function() {
      toggleDropdown(target, dropdownElement);
    });


    // if the target is not a button (not recommended)
    // attach keyboard Enter and Space listeners to mimic the
    // "click" native listeners from buttons
    if (target.tagName !== 'BUTTON') {
      target.addEventListener('keydown', function (event) {
        if (event.key === ' '     || event.keyCode === 32 ||
            event.key === 'Enter' || event.keyCode === 13
        ) {
          toggleDropdown(target, dropdownElement);
        }
      });
    }
  };

  /**
   * Event handlers for when data-event is `hover`
   * @private
   */
  var handleHover = function (target, dropdownElement) {
    var currentTimeOut;

    // shows dropdown when receives tab focus or mouse cursor hovers in
    ['focus', 'mouseenter'].forEach(function (event) {
      target.addEventListener(event, function() {
        clearTimeout(currentTimeOut);
        currentTimeOut = setTimeout(showDropdown, DELAY, target, dropdownElement);
      });
    });

    // hides dropdown when mouse cursor leaves and is not hovering over the dropdown itself
    target.addEventListener('mouseleave', function(event) {
      if (!dropdownElement.contains(event.relatedTarget)) {
        clearTimeout(currentTimeOut);
        currentTimeOut = setTimeout(hideDropdown, DELAY, target, dropdownElement);
      }
    });

    // hides dropdown when target loses tab focus and dropdown content does not receive tab focus
    target.addEventListener('blur', function(event) {
      if (!dropdownElement.contains(event.relatedTarget)) {
        clearTimeout(currentTimeOut);
        currentTimeOut = setTimeout(hideDropdown, DELAY, target, dropdownElement);
      }
    });

    // hides dropdown when mouse cursor leaves the dropdown area or dropdown content loses tab focus
    ['mouseleave', 'focusout'].forEach(function (event) {
      dropdownElement.addEventListener(event, function() {
        clearTimeout(currentTimeOut);
        currentTimeOut = setTimeout(function () {
          if (!dropdownElement.contains(document.activeElement)) {
            hideDropdown(target, dropdownElement, event);
          }
        }, DELAY);
      });
    });
  };

  /**
   * Handles document clicks to close dropdown items when outside clicks happen
   * @private
   */
  var handleDocumentClick = function(event) {
    var activeDropdowns = document.querySelectorAll('.' + ACTIVE_MODIFIER);

    // since IE doesn't support forEach for NodeList, going old fashioned way
    for (var i = 0; i < activeDropdowns.length; i++) {
      // only targets active dropdown that have a target controlled by the plugin
      var activeDropdown = activeDropdowns[i];
      var activeDropdownTarget = activeDropdown.querySelector('[' + TARGET_SELECTOR + ']');
      var activeDropdownElement = activeDropdown.querySelector('#' + activeDropdownTarget.getAttribute(TARGET_SELECTOR));

      if (activeDropdownTarget !== null && activeDropdownElement !== null && !activeDropdown.contains(event.target)) {
        hideDropdown(activeDropdownTarget, activeDropdownElement);
      }
    }
  };

  /**
   * Handles the event listeners based on the target's desired `data-event`
   * @private
   */
  var attachDropdownEventHandlers = function (target, dropdownElement) {
    if (getDropdownEvent(target) === 'click') {
      handleClick(target, dropdownElement);
    }

    if (getDropdownEvent(target) === 'hover') {
      handleHover(target, dropdownElement);
    }

    target.addEventListener('keydown', function (event) {
      // In IE11 and FF36-, the Esc key returns "Esc" instead of "Escape".
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        hideDropdown(target, dropdownElement);
      }
    });

    dropdownElement.addEventListener('keydown', function (event) {
      // In IE11 and FF36-, the Esc key returns "Esc" instead of "Escape".
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        hideDropdown(target, dropdownElement);
      }
    });
  };

  /**
   * Sets basic accessibility attributes to connect the dropdown trigger with the dropdown menu
   * and enable assistive technologies to announce it as such
   * @private
   */
  var setupBaseA11yAttributes = function (target, dropdownElement) {
    const dropdownId = dropdownElement.getAttribute('id');
    const isDropdownHidden = dropdownElement.hasAttribute('hidden');

    target.setAttribute('aria-controls', dropdownId);
    target.setAttribute('aria-expanded', !isDropdownHidden);
    target.setAttribute('aria-haspopup', 'menu');
  };

  /**
   * Initialize the plugin
   */
  dropdown.init =  function () {
    var dropdownTargets = document.querySelectorAll('['+ TARGET_SELECTOR +']');

    // since IE doesn't support forEach for NodeList, going old fashioned way
    for (var i = 0; i < dropdownTargets.length; i++) {
      var target = dropdownTargets[i];
      var dropdownId = target.getAttribute(TARGET_SELECTOR);
      var dropdownElement = document.getElementById(dropdownId);

      if (dropdownElement === null) {
        /* eslint-disable no-console */
        console.warn('dropdown.js plugin error: Cannot find element with id ' + dropdownId + ' for target ' + target);
      } else {
        setupBaseA11yAttributes(target, dropdownElement);
        attachDropdownEventHandlers(target, dropdownElement);
      }
    }

    // clears previous document click listener to avoid multiple calls
    document.removeEventListener('click', handleDocumentClick);
    // adds new document click listener to close open dropdown when user clicks outside of them
    document.addEventListener('click', handleDocumentClick);
  };

  return dropdown;
});
